module.exports = {
  SIMULATION_BINARY_NAME: "code_4",
  PLOTTING_SCRIPTS_NAME: "plot.py",
  FITTING_SACCARIFICATION_GLC_PLOT_NAME: "saccharification_glc_plot.svg",
  FITTING_SACCARIFICATION_XYL_PLOT_NAME: "saccharification_xyl_plot.svg",
  SIMULATION_SACCARIFICATION_PLOT_NAME: "saccharification_plot.svg",
  SIMULATION_ENZYME_ACTIVITY_PLOT_NAME: "enzyme_activity_plot.svg",
  FRAME_JOINER_SCRIPT_NAME: "frame_joiner.py",
  FRAME_MAKER_1_SCRIPT_NAME: "frame_maker_1.py",
  FRAME_MAKER_2_SCRIPT_NAME: "frame_maker_2a.py",
  FRAME_TIME_SCRIPT_NAME: "frame_timelimit_1.py",
  FRAME_COPIER_SCRIPT_NAME: "frame_copier.sh",
  MOVIEMAKER_SCRIPT_NAME: "Moviemaker.sh",
  SERVER_LOGS_FILE_NAME: "server.log",
  FIGURES_FOLDER: "Figures",
  PARAMS_FOLDER: "Params",
  OUTPUT_FOLDER: "Output",
  RUN_FOLDER: "runs",
  DATA_FOLDER: "data",
  LOGS_FOLDER: "logs",
  BUILD_FOLDER: "build",
  OUTPUT_SUBFOLDERS: [
    "3D",
    "DP_distrib",
    "enzyme_activity",
    "enzyme_fraction",
    "enzyme_concentration",
    "Nbr_reactions",
    "saccharification",
  ],
  MAX_SIMULATION_RUNS: 2,
  MAX_FITTING_RUNS: 1,
};
